import React, { FC, memo } from 'react';
import Image from 'next/image';
import { Grid } from '@nextui-org/react';
import styled from 'styled-components';

import { TArrayOfImages } from '../../../mockData/Cooperation';

const CardContainer = styled.div`
  width: 100%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px #4a4949, 20px 20px 10px #ffffff;
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const ImageWrapper = styled.div`
  position: unset;
`;

const CustomImage = styled(Image)`
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset;
`;

const CooperationCard: FC<TArrayOfImages> = ({ img, title, url }) => {
    const redirectLink = (link: string) => {
        window.open(link, '_blank', 'noreferrer');
    };

    return (
        <Grid key={title} xs={4} sm={2}>
            <CardContainer onClick={() => redirectLink(url)}>
                <ImageWrapper>
                    <CustomImage
                        src={img}
                        alt={title}
                        quality={10}
                        unoptimized={true}
                        fill
                    />
                </ImageWrapper>
            </CardContainer>
        </Grid>
    );
};

export default memo(CooperationCard);
