export type TArrayOfImages = {
  title: string;
  img: string;
  url: string;
};

export const arrayOfImages: Array<TArrayOfImages> = [
    {
        title: 'Domani',
        img: '/assets/cooperation/1.svg',
        url: 'https://domani.by'
    },
    {
        title: 'Caparol',
        img: '/assets/cooperation/2.svg',
        url: 'https://www.caparol.by'
    },
    {
        title: 'Villeroy&Boch',
        img: '/assets/cooperation/3.svg',
        url: 'https://www.villeroy-boch.ru'
    },
    {
        title: 'SanMarko',
        img: '/assets/cooperation/4.webp',
        url: 'https://sanmarco-vernici.ru'
    },
    {
        title: 'Skvirel',
        img: '/assets/cooperation/5.webp',
        url: 'http://www.skvirel.by/'
    },
    {
        title: '7Squares',
        img: '/assets/cooperation/6.webp',
        url: 'https://7kvadratov.by/'
    }
];
