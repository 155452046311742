import React, { memo } from 'react';
import { Grid } from '@nextui-org/react';

import { Border, Heading, SubHeading, TextWrapper } from '../../components';
import Container from '../../components/Container';
import { arrayOfImages } from '../../mockData/Cooperation';

import CooperationCard from './CooperationCard';

const Cooperation = () => {
    return (
        <Container id="cooperation" myHeight={80}>
            <TextWrapper width={1200}>
                <Heading>Сотрудничество</Heading>
                <Border />
                <SubHeading style={{ marginBottom: '30px' }}>
          При заказе у наших партнеров мы обеспечиваем лучшие условия по цене и
          гарантируем качество.
                    <br /> А также сопровождаем заказчика при выборе материалов.
                </SubHeading>
                <Grid.Container gap={2}>
                    {arrayOfImages.map((el) => (
                        <CooperationCard {...el} key={el.url} />
                    ))}
                </Grid.Container>
            </TextWrapper>
        </Container>
    );
};

export default memo(Cooperation);
